body,
html {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#title::before {
  content: "- Affahhhh Iyahhhh.....";
  font-family: 'Space Grotesk';
  position: absolute;
  left: -60px;
  font-size: 24px;
  transform: rotate(90deg);
}
#root {
  width: 100%;
  height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
